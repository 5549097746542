import React from 'react';
import { Layout as AntdLayout, Divider } from 'antd';
import ReduxRedirect from '../../Common/ReduxRedirect';

import styles from './index.module.scss';

interface SimpleProps {
  children: React.ReactNode
}

export default function Simple({ children }: SimpleProps): React.ReactNode {
  return (
    <>
      <ReduxRedirect />
      <AntdLayout>
        <div className={styles.signIn}>
          <div className={styles.leftInfo}>
            <div className={styles.header}>
              <img src="/media/finatium_logo.png" alt="logo" />
              <Divider style={{ backgroundColor: '#FFFFFF4D', marginTop: '47px' }} />
              <div>
                <div>test1</div>
                <div>test2</div>
                <div>test3</div>
              </div>
            </div>
            <div>footer</div>
          </div>
          <AntdLayout.Content className={styles.content}>
            {children}
          </AntdLayout.Content>
        </div>
      </AntdLayout>
    </>
  );
}
