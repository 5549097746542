import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Input, Form as AntdForm } from 'antd';
import { Action } from '@triare/auth-redux';
import { AnyObject, Error } from '@triare/auth-redux/dist/saga/common';
import { SignUpAction, signUp as signUpAction } from '@triare/auth-redux/dist/saga/auth/signUp';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../../store';
import { validationSignUp, createRulesForAntd } from '../../../../../utils/validations';
import { moduleName } from '../../../../../store/auth';
import PhoneNumberInput from '../../../../Common/PhoneNumberInput';
import Form from '../Form';
import { ContentProps } from '../index';
import Footer from '../Footer';
import Separator from '../../../../Common/Form/Separator';
import ButtonGoogle from '../Form/ButtonGoogle';
import ButtonApple from '../Form/ButtonApple';
import ButtonFacebook from '../Form/ButtonFacebook';

interface FullRowsProps extends ContentProps {
  authorized: boolean;
  response: AnyObject | null;
  error: Error | null;
  signUp: (payload: SignUpAction) => Action;
}

const validationRules = createRulesForAntd(validationSignUp);

function FullRows({
  signUp, response, onSubmit, error,
}: FullRowsProps): React.ReactNode {
  const [signUpData, setSignUpData] = useState<SignUpAction | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!error && response && onSubmit) {
      onSubmit(signUpData);
    }
  }, [response, error, onSubmit]);

  return (
    <>
      <Form<SignUpAction>
        validationRules={validationRules}
        signUp={({ confirmPassword, ...props }) => {
          const data: SignUpAction = {
            ...props,
            // _query: {
            //   lang: 'en',
            // },
          };

          setSignUpData(data);
          signUp(data);
        }}
        name="signUpFullInfo"
      >
        <AntdForm.Item
          name="firstName"
          label={`${t('firstName')} *`}
          rules={[validationRules]}
        >
          <Input
            placeholder={t('firstName') || 'First name'}
            size="large"
          />
        </AntdForm.Item>

        <AntdForm.Item
          name="lastName"
          label={`${t('lastName')} *`}
          rules={[validationRules]}
        >
          <Input
            placeholder={t('lastName') || 'Last name'}
            size="large"
          />
        </AntdForm.Item>

        <AntdForm.Item
          name="email"
          label={`${t('email')} *`}
          rules={[validationRules]}
        >
          <Input
            placeholder={t('email') || 'Email'}
            size="large"
            readOnly
            onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
          />
        </AntdForm.Item>

        <AntdForm.Item
          name="password"
          label={`${t('password')} *`}
          rules={[validationRules]}
        >
          <Input.Password
            placeholder={t('password') || 'Password'}
            size="large"
          />
        </AntdForm.Item>

        <AntdForm.Item
          name="confirmPassword"
          label={`${t('confirmPassword')} *`}
          dependencies={['password']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('Passwords that you entered do not match!');
              },
            }),
          ]}
        >
          <Input.Password
            placeholder={t('confirmPassword') || 'Confirm password'}
            size="large"
          />
        </AntdForm.Item>

        <AntdForm.Item
          name="phone"
          label={`${t('contactPhone')} *`}
          rules={[validationRules]}
        >
          <PhoneNumberInput
            placeholder="321567926"
            // placeholder={t('phone') || 'Phone'}
            size="large"
          />
        </AntdForm.Item>
      </Form>
      {/*
      <Separator />

      <AntdForm.Item>
        <ButtonGoogle />
      </AntdForm.Item>

      <AntdForm.Item>
        <ButtonApple />
      </AntdForm.Item>

      <AntdForm.Item>
        <ButtonFacebook />
      </AntdForm.Item> */}

      <Footer />
    </>
  );
}

export default connect((state: RootState) => ({
  authorized: state[moduleName].authorized,
  response: state[moduleName].signUp.response,
  error: state[moduleName].signUp.error,
}), {
  signUp: signUpAction,
})(FullRows);
